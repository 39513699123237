import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import Slider from "react-slick";
import AomdownApi from "../../api/aomdown/aomdown.api";
import AuthenApi from '../../api/authen/authen.api';
import MasterDataApi from "../../api/masterdata/masterdata.api";
import InputTextField from "../../components/input/inputTextField";
import { imgs, onImgError } from "../../constants/images";
import { routeName } from "../../routes/routers-name";
import { getToken } from "../../utils/app.utils";
import { decryptString } from "../../utils/crypto-js";
import AccpteCondition from "./accpet-condition/accpte-condtion";
import CardProductDetail from "./card-product-detail/card-product-detail";
import CardProduct from "./card-product/card-product";
import Otp from "./otp/otp";
import PersonalDetail from "./personal-detail/personal-detail";
import SignContact from "./sign-contact/sign-contact";
import liff from "@line/liff";
import { InputAdornment } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export default function RegisterCustomer() {
  const [data, setData] = useState<any[]>([]);
  const [dataBanner, setDataBanner] = useState<any[]>([]);
  const [selectProduct, setSelectProduct] = useState<any>({});
  const [search, setSearch] = useState<string>("");
  const [setpper, setSetpper] = useState<number>(0);
  const history = useHistory();
  const [telCurrentUser, setTelCurrentUser] = useState<any>("");
  const [accessTokenLine, setAccessTokenLine] = useState<string | null>("");
  const [anyToken, setAnyToken] = useState<string | null>("");
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const [defaultUserDetail, setDefaultUserDetail] = useState<any>({})

  const token = getToken()
  const sliderRef: any = useRef(null);
  const location: any = useLocation()
  const checkLogin: any = location?.state?.params?.state

  const settingsSlick = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    dotsClass: "slick-dots custom-dots"
  }

  const loadCurrentUser = async () => {
    const result = await AuthenApi.getUser();
    if (result.status === 200 && result?.data?.data) {
      const tel = result.data?.data?.getCurrent?.tel
      const decryptTel = decryptString(tel);
      setTelCurrentUser(decryptTel); // tel
    }
  }

  const loadCheckStep = async () => {
    const res = await AomdownApi.checkStep();
    const data = res.data.data;
    if (res.status === 200 && data !== null) {
      if (data?.setpper === 5) {
        history.push(routeName.paySlip);
      } else {
        setSetpper(data?.setpper)
      }
    }
  };

  const loadProduct = useCallback(() => {
    let condition: any = {};
    if (search) condition = { ...condition, productName: search };
    MasterDataApi.getProduct(condition).then((res) => {
      if (res.status === 200) {
        setData(res.data.data.result);
      }
    });
  }, [search]);

  const loadBanner = async () => {
    const res = await MasterDataApi.getBanner();
    if (res.status === 200) {
      const data = res?.data?.data;
      setDataBanner(data)
    }
  };

  useEffect(() => {
    loadProduct();
  }, [loadProduct])

  useEffect(() => {
    if (token) {
      loadCheckStep();
      loadCurrentUser();
    }
    loadBanner();

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };

  }, []);

  useEffect(() => {
    if (!checkLogin) {
      // liff.init({ liffId: '2006354132-85QJMJlW', withLoginOnExternalBrowser: true })
      liff.init({ liffId: `${process.env.REACT_APP_LIFF_ID_REGISTER}`, withLoginOnExternalBrowser: true })
        .then(async () => {
          if (liff.isLoggedIn()) {
            const accessTokenLine = await liff.getAccessToken()
            setAccessTokenLine(accessTokenLine)
          } else {
            liff.login();
          }
        })
        .catch((err) => {
          console.log(err);
        })
    }
  }, [checkLogin]);

  const handleScroll = () => {
    const scrollTop = window.scrollY; // Get current scroll position
    if (scrollTop > 200) { // Adjust the value as needed to determine when to stick
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  return (
    <div>
      {_.isEmpty(selectProduct) && setpper < 1 && (
        <>
          <div style={{
            backgroundImage: `url(${imgs.backgroundBanner})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
            className={`input-field-container ${isSticky ? "sticky" : ""}`}
          >
            <div className="pt-4 px-3">
              <Row >
                <Col
                  lg={12}
                  xs={12}
                  sm={12}
                >
                  {!isSticky && <Slider {...settingsSlick} ref={sliderRef}>
                    {
                      _.map(dataBanner, (e: any) => {
                        return (
                          <img src={e.img} width={'100%'} height={'100%'} onError={onImgError} alt="Banner" />
                        )
                      })
                    }
                  </Slider>}
                </Col>
              </Row >
            </div>
            <div className={isSticky ? '' : "pt-4  px-4"}>
              <Row>
                <Col lg={12} xs={12} sm={12} >
                  <div>
                    <InputTextField
                      placeholder="ค้นหารุ่นสินค้า เช่น iphone15"
                      value={search}
                      onchange={(e: any) => {
                        setSearch(e.target.value);
                      }}
                      inputProps={{ startAdornment: <InputAdornment position="start" style={{ zIndex: 1 }}><FontAwesomeIcon icon={faSearch} /></InputAdornment>, }}
                      clearAble
                      onClear={() => { setSearch('') }}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="px-3 py-3">
            <Row>
              {_.map(data, (e: any, index: number) => {
                return (
                  <Col key={index} lg={6} xs={6} sm={6} className="pt-3">
                    <CardProduct
                      key="CardProductKey"
                      img={e.image}
                      name={e.pname}
                      storage={e.storage}
                      color={e.option[0].color}
                      price={e.price}
                      down={e.down}
                      onClick={() => {
                        setSelectProduct(e.code);
                      }}
                    />
                  </Col>
                );
              })}
            </Row>
          </div>
        </>
      )
      }
      {
        !_.isEmpty(selectProduct) && setpper < 1 && (
          <div>
            <CardProductDetail
              key="CardProductDetailKey"
              code={selectProduct}
              onClickBack={() => setSelectProduct({})}
              onClickNext={(e: any) => {
                setSelectProduct(e);
                setSetpper(1);
              }}
            />
          </div>
        )
      }
      {
        setpper == 1 && (
          <Otp
            dataProduct={selectProduct}
            telCurrentUser={telCurrentUser}
            loadCheckStep={() => loadCheckStep()}
            accessTokenLine={accessTokenLine}
            setUserDetail={setDefaultUserDetail}
            onClickNext={(e: any) => {
              console.log('e :>> ', e);
              setAnyToken(e)
              setDefaultUserDetail(e?.userData)
              setSetpper(2);
            }}
          />
        )
      }
      {
        setpper == 2 &&
        <PersonalDetail
          userDetail={defaultUserDetail}
          onClickNext={() => {
            setSetpper(3);
          }}
        />
      }
      {
        setpper == 3 &&
        <AccpteCondition
          onClickNext={() => {
            setSetpper(4);
          }}
        />
      }
      {setpper == 4 && <SignContact accTokenLine={accessTokenLine || ''} />}
    </div >
  );
}
